@use 'styles/mixins';
@use 'styles/variables' as *;

.menuWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100vh;
  max-height: 100%;
  width: 30rem;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.container {
  position: fixed;
  z-index: 500;
  background-color: $keune-background;
  top: 0;
  left: 0;
  height: 100vh;
  max-height: 100%;
  transform: translateX(-100%);
  overflow: hidden;
  box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.12);

  &.visible {
    transform: translateX(0);
  }

  .inner {
    position: relative;
    padding: 4rem 3.1rem;
  }
}

.menu {
  @include mixins.titleTypography;
  font-size: 1.6rem;
  font-weight: 500;

  button {
    @include mixins.titleTypography;
    font-weight: 500;

  }

  li {
    font-weight: 500;
    padding: 1.2rem 0;
  }

  // animation
  li {
    transform: translateX(-60px);
    opacity: 0;
  }
  &.menuIsOpen li {
    transform: translateX(0px);
    opacity: 1;
  }

  // Top level menu
  &.levelOne {
    &.subMenuIsOpen li {
      transform: translateX(-100%);
      opacity: 0;
    }
    li:nth-child(6) {
      margin-top: 0;
      &::before {
        content: '—';
        display: block;
        margin-bottom: 2.4rem;
        color: lightgrey;
      }
    }
    li:nth-child(8) {
      margin-bottom: 0;
      &::after {
        content: '—';
        display: block;
        margin-top: 2.4rem;
        color: lightgrey;
      }
    }
  }

  // Submenu
  &.levelTwo {
    position: absolute;
    top: 0;
    left: 3.1rem;
    padding-top: 5rem;
    opacity: 0;
    pointer-events: none;
    .title {
      font-size: 1.8rem;
      font-weight: bold;
      font-family: $font-body;
    }
    &.visible {
      display: block;
      opacity: 1;
      pointer-events: all;
      li {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }

  // Secondary menu (for professions, support, my account, etc)
  &.secondary {
    border-top: solid 1px $keune-lightgrey;
    padding: 3.7rem 3.1rem;
    opacity: 0;
    display: block;
    li {
      font-size: 1.4rem;
      font-family: $font-body;
      color: black;
    }
    &.menuIsOpen {
      opacity: 1;
    }
    &.subMenuIsOpen {
      display: none;
    }
  }
}

.withSubmenu:first-of-type {
  margin-top: 3rem;
}

.actions {
  display: flex;
  position: absolute;
  z-index: 10;
  &.right {
    right: 0;
    top: 0;
    padding-top: 1.6rem;
    padding-right: 1.1rem;
  }
  &.left {
    left: 1.1rem;
    display: none;
    &.visible {
      display: block;
    }
  }
  .searchAnchor {
    display: flex;
    align-items: center;
  }
  .button {
    height: 1.6rem;
    padding: 0 1.1rem;
  }
  .back {
    left: 0;
    position: absolute;
    top: 0;
  }
}

.clickaway {
  background-color: rgba(67, 52, 102, 0);
  bottom: 0;
  color: transparent;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color 200ms linear 0;
  width: 100%;
  z-index: 200;
  &.visible {
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: auto;
    transition: background-color 600ms ease-out;
  }
}

.wishlistIcon{
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  margin-right: 0.4rem;
  .quantity {
    position: relative;
    font-size: 1rem;
    top: -0.5rem;
    margin-left: 0.2rem;
    color: $keune-black;
  }
}
