@use 'styles/mixins';
@use 'styles/variables';

.container {
  display: flex;
  justify-content: center;
  padding: 0rem 1.6rem;
  @include mixins.media {
    padding: 0rem 6rem;
  }
}
