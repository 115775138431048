@use 'styles/mixins';

.preview {
  position: fixed;
  left: 0;
  bottom: 0;

  z-index: 9999999999;
  pointer-events: none;
  background: forestgreen;
  color: white;
  opacity: 0.75;

  display: flex;
  flex-direction: column;

  padding: 1rem;
  font-size: 1.5rem;

  @include mixins.media {
    padding: 2rem;
    font-size: 2rem;
  }
}

.message {
  margin-top: 1rem;
  font-size: 1rem;
  @include mixins.media {
    margin-top: 1rem;
    font-size: 0.88rem;
  }
}
