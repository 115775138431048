@use 'styles/mixins';
@use 'styles/variables' as *;

@mixin salonSelectorButton {
  @include mixins.standardButton;
  font-size: 1.2rem;
  width: 100%;
}

.findSalon {
  padding: 1.5rem 2.4rem;
  background-color: $keune-lightgrey;

  @include mixins.media {
    margin: 0;
    margin-bottom: 2.5rem;
    width: 42rem;
    padding: 2.8rem;
  }

  .loadingIcon {
    color: white;
  }

  .selectedTitle {
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    @include mixins.media {
      font-size: 2rem;
      margin-bottom: 1.2rem;
    }
  }

  .selectionInformation {
    font-size: 1.4rem;
    line-height: 140%;
    margin-bottom: 2.4rem;
    @include mixins.media {
      margin-bottom: 2.8rem;
    }
  }

  .salonSelectorButtons {
    display: flex;

    .changeSalonButton {
      @include salonSelectorButton;
      height: 4.8rem;
      margin-top: 0;
      background-color: $keune-black;
      color: $keune-white;
      cursor: pointer;
      padding: 0;
      transition: background-color 0.2s ease-in;
      &:hover {
        background-color: rgb(82, 82, 81);
      }
    }

    .removeSalonButton {
      @include salonSelectorButton;
      height: 4.8rem;
      padding: 0;
      border: 1px solid rgba(60, 60, 59, 0.2);
      margin-left: 1.2rem;
      margin-top: 0;
      cursor: pointer;
      transition: background-color 0.2s ease-in;
      &:hover {
        background-color: $keune-white;
      }
    }

    .wantToChangeButton {
      @include salonSelectorButton;
      height: 4.8rem;
      margin-top: 0;
      background-color: $keune-black;
      color: $keune-white;
      cursor: pointer;
      padding: 0;
      width: 100%;
      transition: background-color 0.2s ease-in;
      &:hover {
        background-color: rgb(82, 82, 81);
      }
    }
  }
}
