@use 'styles/mixins';
@use 'styles/variables' as *;

// Container around entire navigation.
.content {
  position: sticky;
  top: 0;
  z-index: 250;
  height: auto;

  .navbar {
    color: $keune-black;
    font-family: $font-headline;
    letter-spacing: 0;
    align-items: center;
    background-color: $keune-background;
    display: flex;
    font-size: 1.4rem;
    font-weight: bold;
    height: $headerHeight;
    padding: 1.2rem 1.6rem;

    position: fixed;
    top: 3.5rem;
    width: 100%;
    z-index: 251;
    border-bottom: 1px solid $keune-lightgrey;

    button {
      @include mixins.titleTypography;
    }

    &.noAnnouncementBar {
      top: 0;
    }

    @include mixins.media {
      padding: 0 $document-margin;
      height: 6rem;
    }
  }
}

// Container for first column, only for mobile.
.mobileFirstColumn {
  display: flex;
  align-items: center;
  @include mixins.media {
    display: none;
  }
  .button {
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    @include mixins.media {
      margin-left: 1.6rem;
      margin-right: 1.6rem;
    }
    &.hamburger {
      width: 1.6rem;
      height: auto;
    }
  }
}

// Container for logo column.
.columnLogo {
  margin-left: 2.9rem;
  margin-right: 1.8rem;
  @include mixins.media {
    margin-left: 0;
    margin-right: 0;
    display: block;
  }
}

.columnWithRightHeader {
  flex: 1;
  text-align: center;

  a {
    display: block;
  }
  @include mixins.media {
    margin-left: 0;
    margin-right: 0;
    display: block;
  }
}

// Container for navigation links, only for desktop.
.columnDesktopNav {
  flex-grow: 1;
  justify-content: left;
  a {
    position: relative;
    display: block;
    &::after {
      content: '';
      position: absolute;
      bottom: -0.05rem;
      left: 0;
      width: 0;
      height: 0.1em;
      background-color: $keune-black;
      transition: width 300ms;
    }
    &:hover::after,
    &:focus::after {
      width: 100%;
    }
  }
  display: none;
  @include mixins.media {
    display: flex;
  }
}

.logo {
  width: 11.1rem;
  // offset the vertical centering to work better with the logo.
  transform: translateY(-0.23rem);
}

.topLevelLinks {
  display: flex;
  li {
    padding: 0 1.2rem;

    &:first-child {
      padding: 0 1.2rem 0 3.6rem;
    }
  }
}

.navBarIcons {
  display: flex;
  align-items: center;
}

.wishlistIcon {
  display: none;
  flex-direction: row;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;

  @include mixins.media {
    display: flex;
  }

  .quantity {
    position: absolute;
    font-size: 1rem;
    top: -0.5rem;
    left: 1.85rem;
    margin-left: 0.2rem;
    color: $keune-black;
  }
}

.cartIcon {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;

  .itemQuantity {
    font-size: 0.8rem;
    position: absolute;
    top: -0.5rem;
    left: 1.4rem;
    margin-left: 0.2rem;
    color: $keune-black;

    @include mixins.media {
      font-size: 1rem;
      position: absolute;
      top: -0.5rem;
      left: 1.6rem;
      margin-left: 0.3rem;
    }
  }
}

.forProfessionalsLink {
  display: none;
  position: relative;
  font-family: $font-headline;
  font-size: 1.4rem;
  margin-top: 0.1rem;
  margin-right: 1.6rem;

  @include mixins.media {
    display: block;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -0.05rem;
    left: 0;
    width: 0;
    height: 0.1em;
    background-color: $keune-black;
    transition: width 300ms;
  }
  &:hover::after,
  &:focus::after {
    width: 100%;
  }
}

// Desktop submenu that appears under the navbar.
.submenu {
  background-color: $keune-background;
  opacity: 0;
  padding: 1.2rem;
  pointer-events: none;
  position: fixed;
  top: $headerHeight;
  z-index: 250;
  display: flex;
  a {
    &:hover {
      border-bottom: solid 1px $keune-black;
    }
  }

  .withTransition {
    transition: opacity 150ms linear, transform 150ms cubic-bezier(0.08, 0.88, 0.72, 0.98),
      top 150ms cubic-bezier(0.08, 0.88, 0.72, 0.98);
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  &.moreMarginTop {
    top: calc($headerHeight + $announcementBarHeight);
  }

  .submenuColumn {
    // padding: 0 2rem;
  }

  .columnTitle {
    color: $keune-black;
    font-size: 1.2rem;
    padding: 1.2rem 2rem;
  }

  li {
    font-size: 1.6rem;
    padding: 1.2rem 2rem;
    white-space: nowrap;
  }
}

// Background overlay, used to capture pointer events and hide the submenu.
.clickaway {
  background-color: rgba(67, 52, 102, 0);
  bottom: 0;
  color: transparent;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color 200ms linear 0;
  width: 100%;
  z-index: 200;
  &.visible {
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: auto;
    transition: background-color 500ms linear 150ms;
  }
}

.button {
  height: 1.6rem;
  margin-left: 1.4rem;
  margin-right: 1.4rem;
  @include mixins.media {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  &.desktopOnly {
    display: none;
    @include mixins.media {
      display: inline;
    }
  }
}

.iconHeight {
  height: 1.6rem;
}

.accountIconWidth {
  height: 2.5rem;
  width: 2.5rem;
}

.accountOffColor {
  color: #ec2227;
}

.accountOnColor {
  color: #72bf44;
}

.accountLink {
  margin-bottom: 0.5rem;
}

.mobileOnly {
  display: block;
  @include mixins.media {
    display: none;
  }
}

.scrollStopper {
  overflow: hidden;
}
