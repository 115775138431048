@use 'styles/mixins';

.paymentIcons {
  display: flex;
  gap: 0.5rem;
}

.iconContainer {
  display: flex;
  border: 0.1rem solid #e6e6e6;
  border-radius: 0.5rem;
  height: var(--iconHeight);
  width: var(--iconWidth);
  position: relative;
  overflow: hidden;
}

.withPadding {
  img {
    padding: 0 0.1rem !important;
  }
}
