@use 'styles/mixins';
@use 'styles/variables' as *;

.unitPrice {
  @include mixins.bodyTypography;
  color: $keune-black-50-percent;
  font-size: 1.125rem;
  height: 1.125rem;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  white-space: nowrap;
  text-transform: none;
  @include mixins.media {
    font-size: 1.4rem;
    height: 1.4rem;
    margin-top: 0.75rem;
  }
}
