@use 'styles/mixins';
@use 'styles/variables' as *;

.main {
  padding-top: $contentPaddingTop;

  &.noAnnouncementBar {
    padding-top: $headerHeight;
  }
  > section:nth-of-type(n + 2) {
    margin-top: 3.5rem;
    @include mixins.media {
      margin-top: 3rem;
    }
  }
  @include mixins.media {
    min-height: 50vh;
  }
}
