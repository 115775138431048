@use 'styles/variables' as *;
@use 'styles/mixins';

@mixin buttonStyle {
  font-size: 1.8rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 100%;
  &:disabled {
    color: #ebebeb;
    cursor: not-allowed;
  }
}

.productCounter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 9.4rem;
  height: 3.6rem;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  color: $keune-black;
  border: 1px solid #ebebeb;
  border-radius: 0.2rem;
}

.minusButton {
  @include buttonStyle;
  border-right: 1px solid #ebebeb;
}

.plusButton {
  @include buttonStyle;
  border-left: 1px solid #ebebeb;
}

.quantity {
  font-size: 1.4rem;
  font-family: $font-body;
  text-align: center;
}
