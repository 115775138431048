@use 'styles/mixins';

.networks {
  display: flex;
  align-items: center;

  svg {
    height: 1.8rem;
    @include mixins.media {
      height: 2.1rem;
    }
  }

  a {
    padding: 0 1.4em;
    @include mixins.media {
      padding: 0 1.7em;
    }
    &:hover svg path {
      // TODO:
      fill: #666;
    }
  }

  li:first-child a {
    padding-left: 0;
  }
}
