@use 'styles/mixins';
@use 'styles/variables';

.richText {
  > p {
    font-size: 1.6rem;
    line-height: 150%;
    font-family: variables.$font-body;
    color: variables.$keune-black;
    margin-top: 1rem;
    white-space: pre-line;
    letter-spacing: -0.05rem;
    @include mixins.media {
      font-size: 1.8rem;
      margin-top: 1rem;
    }
  }

  > p + p {
    margin-top: 1rem;
  }

  > ul {
    @include mixins.listStyling;
    margin-top: 1rem;
    list-style: disc outside none;
    text-align: left;
  }

  > ol {
    @include mixins.listStyling;
    list-style-type: decimal;
    text-align: left;
  }

  > ul + p,
  p + ul {
    margin-top: 2rem;
  }

  li {
    @include mixins.listStyling;
  }

  .bold {
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }

  .sub {
    vertical-align: top;
    position: relative;
    top: -0.5em;
    font-size: small;
  }

  .heading2 {
    display: block;
    scroll-margin-top: 14rem;
    @include mixins.headlineTypography;
    font-size: 2.4rem;
    line-height: 1;
    margin: 3rem 0 0.5rem;

    @include mixins.media {
      font-size: 2.8rem;
      margin: 4rem 0 1.5rem;
    }
  }

  .heading3 {
    display: block;
    scroll-margin-top: 14rem;
    @include mixins.headlineTypography;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 600;
    margin: 3rem 0 0.5rem;

    @include mixins.media {
      font-size: 1.6rem;
      margin: 3rem 0 1.5rem;
    }
  }

  .instagramEmbed {
    margin: 2rem 0;

    @include mixins.media {
      margin: 3.6rem 0;
    }
  }

  .anchorLink {
    margin: 0;
    text-decoration: underline;
  }
}
