@use 'styles/mixins';
@use 'styles/variables' as *;

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.visible {
  display: block;
}

.popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  height: 100%;
  background-color: $keune-background;
  border: 1px solid $keune-lightgrey;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  @include mixins.media {
    width: 50rem;
  }
}

.opened {
  transform: translateX(0);
}

.closeBtn {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.8rem;
  right: 1.8rem;
  cursor: pointer;
}

.cartHeader {
  // @include mixins.titleTypography;
  // font-size: 2rem;
  // line-height: 128%;
  // padding: 1.6rem;
  border-bottom: 1px solid rgba(60, 60, 59, 0.1);
  padding: 1.6rem 6rem 1.6rem 1.6rem;
  .shippingLabel {
    display: block;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  min-height: 5.5rem;
}

.salonsListContainer {
  width: 100% !important;
  overflow-x: hidden;
  margin-bottom: 0;
  padding: 1rem 2.4rem;
}
.salonsListText {
  display: none;
  // font-size: 1.1rem !important;
  // @include mixins.media {
  //   font-size: 1.2rem  !important;
  // }
  // margin-bottom: 1.4rem !important;
}
.salonsListTitle {
  font-size: 1.2rem !important;
  @include mixins.media {
    font-size: 1.4rem !important;
  }
}

.lineItemsSection {
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem;
  @include mixins.media {
    border-top: none;
    border-bottom: none;
    margin-top: 0;
  }
}

.popupContent {
  overflow-y: auto;
  position: relative;
}

.spaceBottom {
  padding-bottom: 4rem;
}

.subContainer {
  border-top: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 499;
  width: 100%;
  background: #f9f9f9;
  bottom: 0;
  font-size: 1.6rem;
  font-family: $font-headline;
  font-style: normal;
  font-weight: normal;
  padding: 0.6rem 1.6rem;

  .subtotal {
    display: flex;
    justify-content: space-between;
    .totalLabel {
      font-size: 1.2rem;
      @include mixins.media {
        font-size: 1.4rem;
      }
      color: $keune-black;
    }
  }
  .shippingInfo {
    align-self: flex-end;
    margin-top: 0.6rem;
    font-size: 1.2rem;
    @include mixins.media {
      font-size: 1.4rem;
    }
  }

  .totalContainer {
    font-size: 1.6rem;
  }

  .checkout {
    font-size: 1.8rem;
    width: 100%;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-in;
    margin-top: 0.6rem;
  }

  .subtotalBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.2rem;
    .cartLink {
      margin: 0.6rem 0;
      text-decoration: underline;
      color: $keune-black;
      &:hover {
        color: black;
      }
    }
    .paymentMethods {
      margin-top: 0.6rem;
    }
  }
}

.salesPrice {
  color: $keune-red;
  margin-left: 0.6rem;
  font-size: 1.6rem;
}

.lineThroughText {
  text-decoration: line-through;
}

.emptyContainer {
  margin: 4.6rem 1.6rem;
  height: 4rem;
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding-left: 1.6rem;
  padding-top: 1.3rem;
  border-radius: 0.2rem;
  background-color: #f2f2f2;
}

.startShoppingButton {
  height: 4.8rem;
  width: 100%;
  font-size: 1.5rem;
  background-color: $keune-black;
  color: white;
  border-radius: 0.2rem;
  margin-top: 0.6rem;
  @include mixins.media {
    height: 4.8rem;
    font-size: 1.4rem;
    line-height: 1.4rem;
    background-color: $keune-black;
    color: white;
  }
}

.freeItemPopup {
  position: fixed;
  bottom: 17rem;
}
