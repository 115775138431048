@use 'styles/mixins';
@use 'styles/variables' as *;

.banner {
  z-index: 251;
  position: fixed !important;
  width: 100vw;
  max-width: 100%;
  background-color: $keune-black;
  color: $keune-white;
  font-size: 1.2rem;
  height: $announcementBarHeight;

  @include mixins.media {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 0 1.4rem;
    @include mixins.media {
      display: flex;
      width: auto;
      text-align: center;
      &:not(:first-child) {
        border-left: 1px solid $keune-white;
      }
    }
  }

  p {
    display: block;
    text-decoration: underline;
    margin-left: 0.3rem;
  }

  .announcementText {
    text-align: center;
    > p {
      font-size: 1.2rem;
      font-family: $font-headline;
      color: $keune-white;
      text-decoration: underline;
      margin-left: 0.3rem;
      margin-top: 0;
    }
  }

  .additionalUSP {
    text-align: center;
    > p {
      font-size: 1.2rem;
      font-family: $font-headline;
      color: $keune-white;
      margin-left: 0.3rem;
      margin-top: 0px;
      text-decoration: none;
    }
  }
}

.salonName:hover {
  cursor: pointer;
}
