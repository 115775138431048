@use 'styles/mixins';
@use 'styles/variables' as *;

.lineItemContainer {
  position: relative;

  .confirmOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // backdrop-filter: blur(1px);
    background-color: rgba(255, 255, 255, 0.8);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .overlayText {
      @include mixins.bodyTypography;
      font-size: 1.4rem;
      padding: 1.6rem;
      font-weight: bold;
      text-align: center;
    }

    .overlayButtons {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0 1.6rem;
      button {
        @include mixins.standardButton;
        font-size: 1.2rem;
        width: 100%;
        border: 1px solid $keune-black;
        margin: 0 0.6rem;
        max-width: 20rem;
      }

      .confirmButton {
        background-color: $keune-black;
        color: $keune-white;
        transition: background-color 0.2s ease-in;
        &:hover {
          background-color: rgb(82, 82, 81);
        }
      }

      .cancelButton {
        transition: background-color 0.2s ease-in;
        background-color: $keune-white;
      }
    }
  }
}

.lineItem {
  display: flex;
  justify-content: space-around;
  @include mixins.media {
    width: 78rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .infoContainer {
    width: 26rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 1.6rem;
  }
  .info {
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    @include mixins.media {
      width: 13rem;
      font-size: 1.8rem;
      display: flex;
    }
  }
  .controls {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.actionButtons {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.rowActtionButtons {
  margin-top: 1.6rem;
  flex-direction: row;
  .actionButton {
    &:not(:first-child) {
      margin-left: 1rem;
      border-left: 0.1rem solid $keune-black;
      padding-left: 1rem;
    }
  }
}

.actionButton {
  font-size: 1.2rem;
  user-select: none;
  text-decoration: underline;
  cursor: pointer;
  text-transform: none;
  &:not(:first-child) {
    margin-top: 1rem;
  }
}

.columnWidth {
  width: 19.5rem !important;
}

.itemImage {
  width: 7.8rem;
  height: 9.6rem;
  border-radius: 2px;
  position: relative;
  background: rgba(0, 0, 0, 0.08);

  Image {
    width: 100%;
    height: 100%;
  }
}

.smallItemImage {
  width: 6rem;
  height: 8rem;
}

.lineWidth {
  width: 78rem;
}

.topBorder {
  border-top: 1px solid rgba(60, 60, 59, 0.1);
}

.mobileSettings {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  // margin: 0 1.6rem;
}

.desktopSettings {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
}

.columnWidthItem {
  min-width: 25rem;
  justify-content: space-between;
  height: 100%;

  .title {
    @include mixins.bodyTypography;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: normal;
    @include mixins.media {
      font-size: 1.2rem;
      font-weight: 400;
      width: 17rem;
    }
  }
}

.infoBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mixins.media {
    font-size: 1.5rem;
    padding: 1.5rem 0;
  }
}

.price {
  @include mixins.bodyTypography;
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  text-transform: none;
  text-align: center;
  width: fit-content;

  @include mixins.media {
    font-weight: 400;
    margin-right: 2rem;
    padding-left: 2rem;
    font-size: 1.5rem;
  }
}

.lineThrough {
  text-decoration: line-through;
  font-size: 1.2rem;
  @include mixins.media {
    font-size: 1.4rem;
  }
}

.discount {
  color: $keune-red;
}

.unitPrice {
  margin-top: 0 !important;
}

.outOfStockTag {
  border-radius: 0.16rem;
  @include mixins.productTag;
  background-color: $keune-black;
  width: fit-content;
  @include mixins.media {
    width: fit-content;
  }
  margin-bottom: 0;
}

.discountTitle {
  color: $keune-gold;
  font-size: 1rem;
  margin-top: 0.5rem;
  @include mixins.media {
    font-size: 1.2rem;
  }
}

.opacTitle {
  // opacity: 0.2;
}

.popupLineItem {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.6rem;
  padding: 1.6rem 0;
  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 1.2rem;
      @include mixins.media {
        font-size: 1.4rem;
      }
      .price {
        margin: 0;
        margin-left: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .additionalData {
        font-size: 1.2rem;
        margin-top: 0.4rem;
        color: $keune-black-50-percent;
      }
    }

    .controls {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .removeButton {
        font-size: 1.1rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.attributes {
  color: $keune-black-50-percent;
  .attributeValue {
    margin-left: 0.5rem;
  }
}

.cartAttributes {
  margin-top: 1.6rem;
  font-size: 1.2rem;
}
