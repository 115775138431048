@use 'styles/mixins';
@use 'styles/variables' as *;

@mixin button {
  @include mixins.headlineTypography;
  letter-spacing: 0;
  width: 100%;
  height: 4.8rem;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 1.4rem;
  line-height: 100%;
  font-weight: 600;
  margin-bottom: 1rem;
  transition: background-color 0.2s ease-in;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.container {
  z-index: 1001;
  border: 1px solid $keune-lightgrey;
  width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  background-color: $keune-background;
  z-index: 1001;
  padding: 1.6rem;
  position: relative;

  @include mixins.media {
    width: 50rem;
  }
}

.message {
  @include mixins.bodyTypography;
  font-size: 2rem;
  margin-bottom: 3rem;
  margin-right: 3rem;
}

.closeBtn {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;
}

.productInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .itemImage {
    width: 7.8rem;
    height: 9.6rem;
    border-radius: 2px;
    position: relative;
    background: rgba(0, 0, 0, 0.08);

    Image {
      width: 100%;
      height: 100%;
    }
  }

  .productTitle {
    @include mixins.bodyTypography;
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin-bottom: 1.6rem;
    padding: 1rem;
    padding-right: 3rem;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.6rem;
  .button {
    @include button;
    &:hover {
      background-color: $keune-white;
    }
  }

  .buttonBlack {
    @include button;
    background-color: $keune-black;
    color: $keune-white;
    &:hover {
      background-color: rgb(82, 82, 81);
    }
  }
}

.loadingIcon {
  color: white;
}
