@use 'styles/mixins';
@use 'styles/variables' as *;

.countrySelector {
  position: relative;
  font-size: 1.2rem;
  font-family: $font-headline;
  @include mixins.media {
    font-size: 1.4rem;
  }
  * {
  }
}

.selected {
  padding: 0.9rem 1.5rem 0.9rem 0;
  z-index: 100;
  &.visible {
    display: block;
  }
  .label {
    text-decoration: underline;
  }
}

.dropdown {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  width: max-content;
  max-height: 30rem;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  border: solid 1px #ebebeb;
  padding: 1rem 0;
  // hidden
  opacity: 0;
  pointer-events: none;
  height: 0;

  .localeContainer {
    display: block;
    height: 3rem;
    padding: 0.9rem 1.5rem;
    text-align: left;
    white-space: nowrap;
    font-family: $font-headline;
    &:hover {
      cursor: pointer;
      background-color: #f2f2f2;
    }
  }
  &.visible {
    opacity: 1;
    height: auto;
    pointer-events: auto;
  }
}

.flag {
  padding-right: 1.2rem;
}

.dropdownBackdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  color: transparent;
  cursor: auto;
  &.visible {
    display: block;
  }
}
