@use 'styles/mixins';
@use 'styles/variables' as *;

.wrapper {
  width: 100%;
}

.form {
  display: flex;
  width: 100%;
  margin-top: 1rem;

  input {
    flex-grow: 1;
    padding-left: 1rem;
    font-family: $font-body;
  }
}

.subtitle p {
  font-size: 1.2rem;
}
.title {
  display: block;
  font-size: 1.8rem;
  padding-bottom: 0.8rem;
  h1 {
    @include mixins.titleTypography;
  }
}

.error {
  display: block;
  margin-top: 1rem;
  font-size: 1.2rem;
  color: $keune-red;
}

.success {
  display: block;
  margin-top: 1rem;
  font-size: 1.2rem;
  color: $success-green;
}
.submitButton {
  width: auto;
  background: $keune-black;
  color: $keune-white;
  border-radius: $button-border-radius;
  font-size: 1.4rem;
  margin-left: 0.7rem;
  padding: 1.5rem 1.4rem;
  @include mixins.media {
    margin-left: 1rem;
    width: 14rem;
  }
}
