@use 'styles/mixins';
@use 'styles/variables';

.playerWrapper {
  position: relative;
  max-width: 100%;
  // height: auto;
  // width: auto;
  aspect-ratio: var(--aspectRatio, 16 / 9);
  //@include mixins.media {
  //  width: 50%;
  //}
}

.dissapearingControls {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    pointer-events: all;
    opacity: 1;
    .videoControl {
      pointer-events:all;
    }
  }
}

.videoControlsWrapper {
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 0;
  top: 0;
  @include mixins.media {
    padding: 2.8rem;
  }

  > * {
    margin-right: 1rem;
  }
}

.videoControl {
  border-radius: variables.$button-border-radius;
  width: 4.8rem;
  height: 4.8rem;

  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  @include mixins.media {
    width: 8rem;
    height: 8rem;
  }
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.thumbnailImage {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  // object-fit: cover;
  height: 100%;
  width: 100%;
}

.videoControlsWrapperV2{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  .playPauseToggle{
    z-index: 100;
  }

  .volumeControl {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 100;
  }
}

