@use 'styles/mixins';

.customSelect {
  display: flex;
  position: relative;
  @include mixins.media {
    display: none;
    max-width: 30rem;
  }
  select {
    @include mixins.headlineTypography;
    letter-spacing: 0;
    background-color: transparent;
    appearance: none;
    border: none;
    position: relative;
    border-radius: 0.25em;
    cursor: pointer;
    padding-right: 2.5rem;

    @include mixins.media {
      line-height: 1.6rem;
      padding-right: 3rem;
    }
  }
  &::after {
    content: '';
    font-size: 1.4rem;
    width: 1.4rem;
    height: 1.4rem;
    background: url(/icons/arrow-down.svg) no-repeat;
    background-size: contain;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
  }
}

.wrapperFlags {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.iconWidth {
  width: 2.2rem;
}

.displayDesktop {
  display: none;
  @include mixins.media {
    display: flex;
  }
}
