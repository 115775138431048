@use 'styles/mixins';
@use 'styles/variables' as *;

@mixin button {
  @include mixins.headlineTypography;
  letter-spacing: 0;
  width: 100%;
  height: 4.8rem;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 1.4rem;
  line-height: 100%;
  font-weight: 600;
  margin-bottom: 1rem;
  transition: background-color 0.2s ease-in;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid $keune-lightgrey;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  background-color: $keune-background;

  width: 92vw;
  height: 316px;
  padding: 2.5rem 2rem;
  border-radius: 0.2rem;

  @include mixins.tablet {
    width: 80vw;
    height: 350px;
    padding: 3rem 2.5rem;
  }

  @include mixins.media {
    width: 600px;
    height: 300px;
    padding: 55px 40px;
  }
}

.message {
  @include mixins.bodyTypography;
  p {
    font-size: 1.4rem;
  }
}

.closeBtn {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;
}

.button {
  // @include mixins.standardButton;
  transition: opacity 0.2s ease-in;
  background-color: $keune-black;
  color: $keune-white;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 0.2rem;
  font-size: 14px;
  margin-top: 1.4rem;

  &:hover {
    opacity: 0.9;
  }
}
