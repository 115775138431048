@use 'styles/mixins';
@use 'styles/variables' as *;

@mixin salonSelectorButton {
  @include mixins.standardButton;
  width: 15rem;
  font-size: 1.2rem;
  @include mixins.media {
    width: 15.6rem;
  }
}

.findSalon {
  padding: 1.5rem 2.4rem;
  background-color: $keune-lightgrey;

  @include mixins.media {
    margin: 0;
    margin-bottom: 2.5rem;
    width: 42rem;
    padding: 2.8rem;
  }

  .loadingIcon {
    color: white;
  }

  .selectedTitle {
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    @include mixins.media {
      font-size: 2rem;
      margin-bottom: 1.2rem;
    }
  }

  .selectionInformation {
    font-size: 1.4rem;
    line-height: 140%;
    margin-bottom: 2.4rem;
    @include mixins.media {
      margin-bottom: 2.8rem;
    }
  }

  .label {
    font-size: 1rem;
    color: $keune-black-50-percent;
    position: absolute;
    padding-top: 1.4rem;
    padding-left: 1.6rem;
    @include mixins.media {
      font-size: 1.2rem;
    }
  }
  select::-webkit-scrollbar {
    width: 0.8rem;
  }
  select::-webkit-scrollbar-track {
    background: white;
  }
  select::-webkit-scrollbar-thumb {
    background: $keune-black-50-percent;
    border-radius: 5rem;
  }
  select {
    height: 6rem;
    width: 100%;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 2px;
    font-family: $font-headline;
    font-weight: 400;
    padding-left: 1rem;
    padding-top: 1.6rem;
    color: $keune-black;

    @include mixins.media {
      font-size: 1.4rem;
    }

    .option {
      font-size: 1.6rem;
      font-family: $font-headline;
      color: $keune-black;

      @include mixins.media {
        font-size: 1.6rem;
      }
    }
  }

  .salonSelectorButtons {
    display: flex;

    .changeSalonButton {
      @include salonSelectorButton;
      height: 4.8rem;
      margin-top: 0;
      background-color: $keune-black;
      color: $keune-white;
      cursor: pointer;
      padding: 0;
      transition: background-color 0.2s ease-in;
      &:hover {
        background-color: rgb(82, 82, 81);
      }
    }

    .removeSalonButton {
      @include salonSelectorButton;
      height: 4.8rem;
      padding: 0;
      border: 1px solid rgba(60, 60, 59, 0.2);
      margin-left: 1.2rem;
      margin-top: 0;
      cursor: pointer;
      transition: background-color 0.2s ease-in;
      &:hover {
        background-color: $keune-white;
      }
    }

    .wantToChangeButton {
      @include salonSelectorButton;
      height: 4.8rem;
      width: 31.3rem;
      margin-top: 0;
      background-color: $keune-black;
      color: $keune-white;
      cursor: pointer;
      padding: 0;
      transition: background-color 0.2s ease-in;
      &:hover {
        background-color: rgb(82, 82, 81);
      }

      @include mixins.media {
        width: 32.4rem;
      }
    }
  }
}

.modalShadow {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(42, 42, 42, 0.5);

  .salonSelectorModal {
    width: 34.3rem;
    height: 61.2rem;
    background-color: $keune-white;
    border-radius: 2px;
    padding: 4.8rem 3.1rem 2.8rem 3.1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    @include mixins.media {
      width: 60rem;
      height: 58rem;
      padding: 3.5rem 6rem;
    }

    .closeButton {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;
      margin-left: auto;
      @include mixins.media {
        top: 2.8rem;
        right: 2.4rem;
      }
    }

    .selectSalonText {
      @include mixins.headlineTypography;
      max-width: 28.1rem;
      font-size: 2.4rem;
      line-height: 117%;
      @include mixins.media {
        max-width: 48rem;
        font-size: 2.8rem;
        line-height: 114%;
      }
    }

    .salonChoiceDescription {
      @include mixins.bodyTypography;
      font-size: 1.4rem;
      line-height: 143%;
      margin-top: 1.6rem;
      @include mixins.media {
        margin-top: 2rem;
      }
    }

    .searchBarWrapper {
      display: flex;
      align-items: center;
      width: 100%;
      height: 4.8rem;
      border: 1px solid $keune-lightgrey;
      border-radius: 2px;
      box-sizing: border-box;
      margin-top: 2.4rem;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      @include mixins.media {
        margin-top: 2.8rem;
      }
      .searchBar {
        border: none;
        background: none;
        @include mixins.headlineTypography;
        color: $keune-black-50-percent;
        font-size: 1.4rem;
        font-weight: normal;
        width: 100%;
      }
    }

    .optionsView {
      display: flex;
      overflow-y: scroll;
      flex-direction: column;
      height: 20.8rem;
      width: 100%;
      margin-top: 2.4rem;
      @include mixins.media {
        margin-top: 2.8rem;
      }

      &::-webkit-scrollbar {
        border: none;
        width: 0.4rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $keune-black;
        height: 10rem;
        @include mixins.media {
          height: 8rem;
        }
      }
    }

    .salonOption {
      display: flex;
      align-items: center;
      position: relative;
      width: 26.5rem;
      height: 4rem;
      border-bottom: 1px solid $keune-lightgrey;
      @include mixins.media {
        width: 46rem;
      }

      .salonCheckbox {
        /* Reset base appearance of radio button*/
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        display: grid;
        place-content: center;
        margin: 0;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        border: 0.1px solid $keune-lightgrey;
        box-sizing: border-box;

        &::before {
          content: '';
          width: 1rem;
          height: 1rem;
          background-color: $keune-black;
          margin: 0.3rem;
          border-radius: 50%;
          transform: scale(0);
        }

        &:checked::before {
          transform: scale(1);
        }
      }

      .salonName {
        @include mixins.headlineTypography;
        max-width: 23.7rem;
        margin-left: 1.2rem;
        font-size: 1.4rem;
        @include mixins.media {
          max-width: 42.8rem;
          margin-left: 1.6rem;
        }
      }
    }

    .buttonsSection {
      display: flex;
      width: 100%;
      margin-top: 4rem;
      @include mixins.media {
        margin-top: 2.8rem;
      }

      button:nth-child(2) {
        margin-left: 1.2rem;
      }

      .confirmChoice {
        @include mixins.standardButton;
        color: rgb(255, 255, 255);
        width: 13.4rem;
        height: 4.8rem;
        margin-top: 0;
        padding: 1.2rem;
        background-color: $keune-black;
        cursor: pointer;
        transition: background-color 0.2s ease-in;
        @include mixins.media {
          width: 23.4rem;
        }
        &:hover {
          background-color: rgb(82, 82, 81);
        }
      }

      .cancelAction {
        @include mixins.standardButton;
        color: $keune-black;
        width: 13.4rem;
        height: 4.8rem;
        margin-top: 0;
        padding: 1.2rem;
        cursor: pointer;
        border: 1px solid rgba(60, 60, 59, 0.2);
        transition: background-color 0.2s ease-in;
        @include mixins.media {
          width: 23.4rem;
        }
        &:hover {
          background-color: rgb(235, 235, 235);
        }
      }
    }
  }
  .loadingIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
