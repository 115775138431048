@use 'styles/variables';
@use 'styles/mixins';

$openVolumeWidth: 14rem;

.volumeToggle {
  transition: 0.15s width ease-in-out;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  overflow: hidden;
}

.withVolumeSlider {
  &:hover {
    width: $openVolumeWidth;
    @include mixins.media {
      width: calc(#{$openVolumeWidth} + 8rem);
    }
  }
}

.volumeSliderWrapper {
  width: $openVolumeWidth;
  @include mixins.media {
    width: calc(#{$openVolumeWidth} + 6rem);
  }
  display: flex;
  position: absolute;
  align-items: center;
  left: 0;

  .customVolumeSlider {
    width: calc(#{$openVolumeWidth} - 6rem);
    @include mixins.media {
      width: calc(#{$openVolumeWidth} - 3rem);
    }
    margin-left: 1.5rem;
  }

  button {
    flex-shrink: 0;
    width: 4.8rem;
    height: 4.8rem;
    @include mixins.media {
      width: 8rem;
      height: 8rem;
    }
  }
}
