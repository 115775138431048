@use 'styles/mixins';
@use 'styles/variables' as *;

.container {
  border: 1px solid $keune-lightgrey;
  background: $keune-white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  width: calc(100% - 3.2rem);
  margin: 0 1.6rem;
  user-select: none;
  ::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }
  ::-webkit-scrollbar-track {
    background: $keune-white;
  }
  ::-webkit-scrollbar-thumb {
    background: $keune-black-50-percent;
    border-radius: 5rem;
  }
}

.mainContent {
  overflow-y: auto;
  max-height: 40rem;
  padding: 1.6rem;
}

.collapsed {
  width: auto !important;
  position: fixed;
  padding: 0;
  border: none;
}

.expandBtn {
  text-transform: uppercase;
  background-color: $keune-black;
  color: $keune-white;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.closeBtn {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.8rem;
  right: 1.8rem;
  cursor: pointer;
}

.itemGroup {
  margin-bottom: 2.4rem;
  &:last-child {
    margin-bottom: 0;
  }
  .groupTitle {
    @include mixins.headlineTypography;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }
}

.item {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1.6rem;
  padding: 1.6rem;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(60, 60, 59, 0.1);
  }
  .itemImage {
    grid-row: span 2;
    width: 5rem;
    height: 6rem;
    border-radius: 2px;
    position: relative;
    background: rgba(0, 0, 0, 0.08);
    Image {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    @include mixins.bodyTypography;
    font-size: 1.2rem;
    @include mixins.media {
      font-size: 1.4rem;
    }
  }
  .addToCartButton {
    @include mixins.bodyTypography;
    text-transform: uppercase;
    text-decoration: underline;
    align-self: end;
    justify-self: start;
    font-size: 1.1rem;
  }
}

.itemSlide {
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 9rem;
  @include mixins.media {
    width: 12rem;
  }
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  .itemImage {
    width: 9rem;
    height: 12rem;

    @include mixins.media {
      width: 12rem;
      height: 15rem;
    }
    border-radius: 2px;
    position: relative;
    background: rgba(0, 0, 0, 0.08);
    Image {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    @include mixins.bodyTypography;
    font-size: 1.2rem;
  }
  .addToCartButton {
    @include mixins.standardButton;
    color: $keune-black;
    font-size: 1.2rem;
    width: 100%;
    margin-top: 0;
    padding: 0.6rem 0;
    cursor: pointer;
    border: 1px solid rgba(60, 60, 59, 0.2);
    transition: background-color 0.2s ease-in;
    &:hover {
      background-color: rgb(235, 235, 235);
    }
  }
}

.slider {
  width: auto;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  padding: 0.6rem 0;
}

.itemsContainer {
  display: flex;
  align-items: start;
  gap: 1rem;
}

.clickDisabled {
  pointer-events: none;
}

.prices {
  width: 100%;
  @include mixins.bodyTypography;
  font-size: 1.2rem;
  .price {
    text-decoration: line-through;
    margin-right: 0.5rem;
  }
}

.unitPrice {
  font-size: 1rem;
  width: 100%;
  margin-top: 0;
}

.info {
  display: grid;
  grid-template-columns: auto;
  gap: 0.6rem;
}
