@use 'styles/mixins';
@use 'styles/variables' as *;

.footer {
  // display: none;
  color: $keune-black;
  position: relative;
  width: 100vw;
  max-width: 100%;
  z-index: 100;
  border-top: 1px solid $keune-lightgrey;
  padding: 3rem 3.1rem 4.8rem;

  @include mixins.media {
    // display: none;
    padding: 2.5rem $document-margin 4.8rem;
  }

  .menu {
    column-count: 2;
    font-size: 1.2rem;
    line-height: 3rem;
    font-family: $font-headline;
    margin-bottom: 4.8rem;
    // margin-right: -6rem; // To support uneven columns
    max-width: 84.5rem;

    @include mixins.media {
      font-size: 2rem;
      line-height: 5rem;
    }
  }
}

.hasMargin {
  margin-top: 6.4rem;
  @include mixins.media {
    margin-top: 10.1rem;
  }
}

.rowLocaleSelector {
  @include mixins.media {
    float: left;
    margin-right: 5.6rem;
    z-index: 10;
    position: relative;
  }
}

.rowSocial {
  margin: 2.8rem 0 5.1rem;
  @include mixins.media {
    transform: translateY(0.3rem);
  }
}

.rowIcon {
  display: flex;
  flex-direction: row;
  @include mixins.media {
    float: right;
    transform: translateY(-2rem);
  }

  .icon {
    width: 3.5rem;
    height: 3.5rem;
    position: relative;
    margin-right: 1.7rem;
  }
}

.rowNewsletter {
  @include mixins.media {
    float: right;
    max-width: 46rem;
  }
}

.secondary {
  font-size: 1.2rem;
  margin-bottom: 2.8rem;
  .title {
    margin-bottom: 1.2rem;
  }
  .links {
    display: flex;
    li {
      margin-right: 1.6rem;
      h3 {
        font-size: 1.2rem;
      }
    }
  }

  @include mixins.media {
    max-width: fit-content;
    float: left;
    font-size: 1.4rem;
    .title {
      float: left;
      margin-right: 2rem;
    }
  }
}

.footerLogo {
  width: 10rem;
}
