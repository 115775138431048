@use 'styles/mixins';
@use 'styles/variables' as *;

.container {
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: flex-start;
  // background: linear-gradient(90deg, $keune-dark-red 0%, rgba(244,244,0,1) 50%, $success-green 100%);
  border: 1px solid $keune-lightgrey;
  background-color: $keune-lightgrey;
  @include mixins.media {
    height: 0.8rem;
  }
}

.progress {
  height: 100%;
  transition: width 0.3s ease-in-out;
  background: $success-green;
}

